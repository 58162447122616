import React from 'react';
import './CSS/Client.scss';
import web1 from './image/icnwi.webp'
import web2 from './image/oharaCom.webp'
import web3 from './image/sandysHub.webp'

const projects = [
  {
    title: `Igbo Cultural Association North-west Ireland: Cultural Integration Website`,
    image: web1, // Replace with your project image URL
    link:'https://igboculturalassociation.com/'
  },
  {
    title: `Kevin OHara's Foundation: Charitable Initiative Website`,
    image: web2,
    link: 'https://oharafoundation.com/', // Add the link for the second project
  },
  {
    title: `Sandys African Food Hub: A Dynamic Restaurant Website`,
    image: web3,
    link: 'https://sandysafricanfoodhub.com/', // Add the link for the third project
  },
  // Add more project entries as needed
];

const Client = () => {
  return (
    <div id="client" className="client-container">
      <h1>Clients and Projects</h1>
      <div className="projects w-screen">
        {projects.map((project, index) => (
          <div className="project" key={index}>
            <div className="image">
              <a href={project.link} target="_blank" rel="noopener noreferrer">
                <img src={project.image} alt={project.title} />
              </a>
            </div>
            <h5 className="title">{project.title}</h5>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Client;

import React, { useState, useEffect, useRef } from 'react';
import { useSpring, animated } from 'react-spring';
import About from './About';
import img from '../components/image/code2-RM.webp'

import styled from 'styled-components'
import MenuBar from './MenuBar'
import Lottie from 'lottie-react';
import animate from '../Animation2.json'
import animatImage from '../Animation3.json'
import '../components/CSS/Home.scss'
import gsap from 'gsap';

import video2 from '../components/AstroFile.mp4'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';



const Home = () => {
let onMotion= useRef();
const videoEl = useRef(null);
const [animationActive, setAnimationActive] = useState(true);
const backgroundRef = useRef(null);
const attemptPlay = () => {
  videoEl &&
  videoEl.current &&
  videoEl.current.play().catch(error => {
  console.error("Error attempting to play", error);
    });
};


useEffect(() => {
  const interval = setInterval(() => {
    setAnimationActive((prev) => !prev);
  }, 10000); // Adjust the interval duration (in milliseconds) as needed

  return () => clearInterval(interval);
}, []);

useEffect(() => {
  if (backgroundRef.current) {
    const backgroundElement = backgroundRef.current;
    if (animationActive) {
      backgroundElement.classList.add('active');
    } else {
      backgroundElement.classList.remove('active');
    }
  }
}, [animationActive]);
  useEffect(() => {
  
gsap.to(onMotion,
  1,
  {
  scale: 0.1, 
  y: 60,
  yoyo: true, 
  repeat: -1, 
  ease: 'Power0.easeNone',
  timeScale: 0 ,
  delay: 1,
  stagger: {
  amount: 1,
  from: 'top',
  y:200,
  x:-300
  }
});
  }, []);


  return (
    <>
{<MenuBar/>}
<HOME id="home"  className="wrappo relative min-h-screen h-auto min-w-screen w-auto ">
  <div className="video-player w-screen">
<video className='bg-video ' 
   src={video2}
   muted
   autoPlay
   loop 
   alt= 'My brand like image'
   animation-duration= '3s'
   > 
</video>
</div>
      
<div  className= "main container overflow-hidden" >
<div className=' section-1'>
 <div className="image-box">
  <img src={img} alt="top-image" />
  </div> 
 </div>
</div>

 <div className="section-2  container w-full  ">
<div className='animate absolute left-0 right-0 top-0  max-h-40'>
        <Lottie animationData={animatImage}/>
      </div>
<div className="intro-box-2">
  <h1 className='text-capitalize -webkit-font-smoothing: auto
  -moz-osx-font-smoothing: auto z-50 text-4xl relative'>Hello</h1>


    <h2 className=''>I'm <span className='tex'>Francis</span> 
      <br/>An Enthusiastic Web Developer
      </h2>

      <ul className=''>
      <li className=' list-education-1  py-1 '><span>
      <svg xmlns="http://www.w3.org/2000/svg" height="1em" viewBox="0 0 640 512"><path d="M320 32c-8.1 0-16.1 1.4-23.7 4.1L15.8 137.4C6.3 140.9 0 149.9 0 160s6.3 19.1 15.8 22.6l57.9 20.9C57.3 229.3 48 259.8 48 291.9v28.1c0 28.4-10.8 57.7-22.3 80.8c-6.5 13-13.9 25.8-22.5 37.6C0 442.7-.9 448.3 .9 453.4s6 8.9 11.2 10.2l64 16c4.2 1.1 8.7 .3 12.4-2s6.3-6.1 7.1-10.4c8.6-42.8 4.3-81.2-2.1-108.7C90.3 344.3 86 329.8 80 316.5V291.9c0-30.2 10.2-58.7 27.9-81.5c12.9-15.5 29.6-28 49.2-35.7l157-61.7c8.2-3.2 17.5 .8 20.7 9s-.8 17.5-9 20.7l-157 61.7c-12.4 4.9-23.3 12.4-32.2 21.6l159.6 57.6c7.6 2.7 15.6 4.1 23.7 4.1s16.1-1.4 23.7-4.1L624.2 182.6c9.5-3.4 15.8-12.5 15.8-22.6s-6.3-19.1-15.8-22.6L343.7 36.1C336.1 33.4 328.1 32 320 32zM128 408c0 35.3 86 72 192 72s192-36.7 192-72L496.7 262.6 354.5 314c-11.1 4-22.8 6-34.5 6s-23.5-2-34.5-6L143.3 262.6 128 408z" fill='#84bcf153'/></svg>
        
        </span>
     <span className=' education me-3'>A Graduate of Computer Sc<span className="graduate">ience</span></span>
   
        </li>
      <li className=' list-education-2 '><span>
      <svg xmlns="http://www.w3.org/2000/svg" height="1em" viewBox="0 0 640 512"><path d="M320 32c-8.1 0-16.1 1.4-23.7 4.1L15.8 137.4C6.3 140.9 0 149.9 0 160s6.3 19.1 15.8 22.6l57.9 20.9C57.3 229.3 48 259.8 48 291.9v28.1c0 28.4-10.8 57.7-22.3 80.8c-6.5 13-13.9 25.8-22.5 37.6C0 442.7-.9 448.3 .9 453.4s6 8.9 11.2 10.2l64 16c4.2 1.1 8.7 .3 12.4-2s6.3-6.1 7.1-10.4c8.6-42.8 4.3-81.2-2.1-108.7C90.3 344.3 86 329.8 80 316.5V291.9c0-30.2 10.2-58.7 27.9-81.5c12.9-15.5 29.6-28 49.2-35.7l157-61.7c8.2-3.2 17.5 .8 20.7 9s-.8 17.5-9 20.7l-157 61.7c-12.4 4.9-23.3 12.4-32.2 21.6l159.6 57.6c7.6 2.7 15.6 4.1 23.7 4.1s16.1-1.4 23.7-4.1L624.2 182.6c9.5-3.4 15.8-12.5 15.8-22.6s-6.3-19.1-15.8-22.6L343.7 36.1C336.1 33.4 328.1 32 320 32zM128 408c0 35.3 86 72 192 72s192-36.7 192-72L496.7 262.6 354.5 314c-11.1 4-22.8 6-34.5 6s-23.5-2-34.5-6L143.3 262.6 128 408z" fill='#84bcf153'/></svg>
        
        </span>
        <span className='education me-3'>A Graduate of Business Admin<span className="graduate">istration</span></span>
        
        </li>


 

      </ul>
  
      <h6 className='text-small' style={{zIndex:'2000'}}>
      Have a deep passion for taking on demanding challenges and a steadfast dedication to delivering measurable results.     
       </h6>
    </div>
 <div className="image-article"></div>

</div>
 


</HOME>

    </>
 
  )
}

export default Home
const HOME= styled.div`

.animate{
min-width:600px !important;
max-width:600px ! important;
min-height:600px !important;
max-height:600px !important;
z-index: 2 !important;
overflow: hidden !important
}
`
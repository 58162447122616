import React, {useRef} from 'react'
import { Navbar, Nav, NavbarBrand, Container} from 'react-bootstrap-v5';
import styled from 'styled-components';
import '../components/CSS/NavBar.scss'
import {HashLink as Link} from 'react-router-hash-link'
/* import { Link } from 'react-scroll';
 */


const MenuBar = () => {

  return (
    <NAVBAR className ='d-flex justify-center align-middle object-center max-w-full navbar-page ' fixed='top'>
    <Container className='relative  nav-menu '>
    <NavbarBrand className='logo d-flex'>
    <Link to='#home' smooth duration={1000} offset={0} >
   <h3 className=" text-lime-200/100 mix-blend-mode: color-dodge 
translate-y-2
">
  <span className='text-lime-100/100 text-shadow shadow-lime-500 '>Alu</span>devs
</h3>
</Link>
<Link to='#home' smooth duration={1000} offset={-0} className='translate-x-32' style={{textDecoration:'none'}}>
 <span className='text-light home-icon'>

 <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="currentColor" className="bi bi-house-door translate-y-1" viewBox="0 0 16 16" >
  <path d="M8.354 1.146a.5.5 0 0 0-.708 0l-6 6A.5.5 0 0 0 1.5 7.5v7a.5.5 0 0 0 .5.5h4.5a.5.5 0 0 0 .5-.5v-4h2v4a.5.5 0 0 0 .5.5H14a.5.5 0 0 0 .5-.5v-7a.5.5 0 0 0-.146-.354L13 5.793V2.5a.5.5 0 0 0-.5-.5h-1a.5.5 0 0 0-.5.5v1.293zM2.5 14V7.707l5.5-5.5 5.5 5.5V14H10v-4a.5.5 0 0 0-.5-.5h-3a.5.5 0 0 0-.5.5v4z"/>
</svg>


 </span>
 
 </Link>
    </NavbarBrand>
    <Nav>
      <span className='d-flex navlink-wrapper'>
        <span className='d-flex span-link1'>
        <Link  to="#contact" smooth offset={0} duration={100}
        className='nav-link cursor-pointer' > Contact 
        </Link>

        <Link to="#about" smooth offset={0} duration={1000}
        className='nav-link cursor-pointer' >
        About me 
        </Link>

        <Link to="#client" smooth offset={0} duration={1000}
        className='nav-link z-0 cursor-pointer client-link' > 
        Clients 
        </Link>
        </span>

         <span className='d-flex span-link2'>
        <Link to="#progressbar" smooth offset={0} duration={1000}
        className='nav-link cursor-pointer' > 
        Portfolio 
        </Link>
       
        </span>
        </span>
    </Nav>
    </Container>

    </NAVBAR>
  )
}

export default MenuBar
const NAVBAR = styled(Navbar)`

max-width:auto;
padding-top:1rem !important;
z-index:500 !important;
margin-right:0.9rem !important;
padding-right:0 !important;


`
import './App.css';
import Home from './components/Home';
import '../node_modules/bootstrap/dist/css/bootstrap.css'
import '../node_modules/bootstrap/dist/js/bootstrap.js'
import About from './components/About';
import Skill from './components/Skill';
import Client from './components/Client';
import ContactMe from './components/ContactMe';
import 'animate.css';


function App() {
  return (
    <div className="App">
      <div className='App-header'>

        <Home />
        <About/>
        <Skill />
        <Client />
         <ContactMe/>
      </div>
    </div>
  );
}

export default App;

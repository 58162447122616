import React, { Component } from 'react';
import './CSS/Skill.scss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faDatabase } from '@fortawesome/free-solid-svg-icons';
import { faJava, faCss3,faHtml5, faNode, faReact, faJs } from '@fortawesome/free-brands-svg-icons';

class CircularProgressBar extends Component {
  constructor(props) {
    super(props);
    this.state = {
        skills: [
            {
              name: 'Html',
              percent: 80,
              icon: faHtml5, // Replace with the actual FontAwesome icon name
            },
            {
              name: 'Java',
              percent: 50,
              icon: faJava, // Replace with the actual FontAwesome icon name
            },
            {
              name: 'JavaScript',
              percent: 65,
              icon: faJs, // Replace with the actual FontAwesome icon name
            },
          /*   {
              name: 'CSS',
              percent: 70,
              icon: faCss3, // Replace with the actual FontAwesome icon name
            }, */
            {
              name: 'Node.js',
              percent: 50,
              icon: faNode, // Replace with the actual FontAwesome icon name
            },
            {
              name: 'React.js',
              percent: 60,
              icon: faReact, // Replace with the actual FontAwesome icon name
            },
            {
              name: 'SQL',
              percent: 60,
              icon: faDatabase, // Replace with the actual FontAwesome icon name
            },
          ],
          
        };
        console.log(this.state.skills)
      }
  render() {
    const { skills } = this.state;
   
    return (
      <div id="progressbar" className="skill-container w-screen relative  d-flex  justify-center align-middle py-3 pb-5 ">
        
      <div className="progress-container relative ">
      <header className='w-full pb-5 relative  d-flex justify-center align-middle -top-5'>
    <h1 className=' skill-h1'> Personal Skills</h1>
    </header>


        {skills.map((skill, index) => (
          <div key={index}>
           
            <h2>  <FontAwesomeIcon icon={skill.icon} className='icons' /></h2>
            <svg className="progress-ring" width="120" height="120">
              <circle
                className="progress-ring-circle"
                r="35"
                cx="60"
                cy="60"
                fill="transparent"
                strokeDasharray="239"
                strokeDashoffset={239 - (239 * skills[skill]) / 100}
              />
            </svg>
            <h5>{skill.name}</h5>
            <div className="progress-text">{skill.percent}%</div>
          </div>
        ))}
      </div>
      </div>
    );
  }
}

export default CircularProgressBar;

import React from 'react';
import '../components/CSS/About.scss'; // Create this CSS file for styling

const About = () => {
  return (
    <div id="about"  className="about-containers w-screen relative ">
      <div className="about-content container relative ">

        <h1 className=' aboutme'>About Me</h1>
        <div className="article relative">
        <p>
          I'm a passionate web developer driven by an insatiable appetite for problem-solving and a commitment to delivering excellence. My journey into web development has been an exciting adventure.  My love for this extends beyond the screen. It's about connections, solving problems, and enhancing the human experience. From building elegant user interfaces to optimizing code for peak performance, I approach each challenge with enthusiasm.
          </p>
          <p>
          The heart of my passion lies in solving problems. There's a thrill in tackling complex challenges and finding elegant solutions that improve lives. This relentless pursuit of excellence keeps me coming back to this field. I've had the privilege of working with clients and projects, each one teaching me something new and pushing me to evolve. My belief is that the journey to web development excellence is ongoing, marked by milestones in the form of code.
          So, whether you're a collaborator, client, or fellow enthusiast, I invite you to join me on this journey. Together, we can turn your digital dreams into reality.
        </p>
        </div>
      </div>
  
    </div>
  );
};

export default About;

// ContactForm.js

import React, { useState, useRef } from 'react';
import '../components/CSS/ContactMe.scss';
import emailjs from '@emailjs/browser';

const ContactForm = () => {
  const form= useRef()
  const handleFocus = (e) => {
    e.target.style.color = 'black';
    e.target.style.paddingLeft = '1rem';
  };
  const sendEmail = (e) => {
    e.preventDefault();

    emailjs.sendForm('service_u5zj9gw', 'template_0g4dma3', form.current, 'SiiUbauuIvwp8Z5P2')
      .then((result) => {
          console.log(result.text);
      }, (error) => {
          console.log(error.text);
      });
      e.target.reset();
  };

  

  return (
    <form
      onSubmit={sendEmail} ref={form}
      className='d-flex flex-col gap-2'
    >
      <label className='d-flex flex-col'>
        <span>Name</span>
        <input
          type="text"
          name="user_name" 
          onClick={handleFocus}
        />
      </label>
      <label className='d-flex flex-col'>
        Email:
        <input
          type="email"
          name="user_email"
          onClick={handleFocus}
        />
      </label>
      <label className='d-flex flex-col'>
        Message:
        <textarea
          name="message"
          onClick={handleFocus}
        />
      </label>
      <button className='' type="submit">Submit</button>
    </form>
  );
};

export default ContactForm;
